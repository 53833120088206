import TextField from '@mui/material/TextField'
import React, { useState, useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import DeleteIcon from '@mui/icons-material/Delete'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { getExploreVideo, getAllStyles, getCategories } from '../graphql/queries'
import ProgressBar from '../components/circularProgress/circularProgress'
import ReactPlayer from 'react-player'
import './video.css'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { updateExplore, deleteExplore } from '../graphql/mutation'
import Alert from '@mui/material/Alert'
import { uploadToS3 } from '../utils/uploadToS3'

const selectMotionScoreData = [
  { value: 1, name: 'ONE' },
  { value: 2, name: 'TWO' },
  { value: 3, name: 'THREE' },
  { value: 4, name: 'FOUR' },
  { value: 5, name: 'FIVE' },
  { value: 6, name: 'SIX' },
  { value: 7, name: 'SEVEN' },
  { value: 8, name: 'EIGHT' },
  { value: 9, name: 'NINE' },
  { value: 10, name: 'TEN' },
]
const selectAspectRatioData = [
  { value: 'WIDESCREEN', name: 'WIDESCREEN' },
  { value: 'PORTRAIT', name: 'PORTRAIT' },
  { value: 'SQUARE', name: 'SQUARE' },
  { value: 'FULL_SCREEN', name: 'FULL SCREEN' },
  { value: 'TABLET', name: 'TABLET' },
  { value: 'ULTRAWIDE', name: 'ULTRAWIDE' },
]


export default function SingleExplorePage() {
  const { id } = useParams()
  const [videoData, setVideoData] = useState(null)
  const [message, setMessage] = useState(null)
  const [gif, setGif] = useState(null)
  const [selectedGifUrl, setSelectedGifUrl] = useState()
  const [categorie, setCategories] = useState([])
  const [previewMode, setPreviewMode] = useState(false)
  const [explore, setExplore] = useState({
    prompt: '',
    url: '',
    thumbnailUrl: '',
    motionScore: '',
    style: '',
    aspectRatio: '',
    gif: '',
    categoryId: '',
    categoryName: '',
  })
  const { loading: loadingStyles, error: errorStyles, data: dataStyles } = useQuery(getAllStyles)
  const { loading: loadingCategories, error: errorCategories, data: dataCategories } = useQuery(getCategories)
  const { loading, error, data } = useQuery(getExploreVideo, {
    variables: { exploreId: id },
    onCompleted: (data) => {
      setVideoData(data.getExploreVideo)
    },
  })
  const selectStyleData = dataStyles && dataStyles.getAllStyles ? dataStyles.getAllStyles : []
  const selectCategories = dataCategories && dataCategories.getCategories ? dataCategories.getCategories : []

  useEffect(() => {
    if (videoData) {
      setExplore({
        prompt: videoData.prompt,
        url: videoData.url,
        thumbnailUrl: videoData.thumbnailUrl,
        motionScore: videoData.motionScore,
        style: videoData.style,
        aspectRatio: videoData.aspectRatio,
        gif: videoData.gif,
        categoryName: videoData.category?.name,
        categoryId: videoData.category?.id,
      })
      setSelectedGifUrl(videoData.gif)
    }
  }, [videoData])
  const [updateExploreMutation] = useMutation(updateExplore)
  const [deleteExploreMutation] = useMutation(deleteExplore)

  if (loading) {
    return <ProgressBar />
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }

  const handlePreviewClick = () => {
    setPreviewMode(!previewMode) // Toggle preview mode
  }
  const handleChange = (e) => {
    const { name, value } = e.target
    setExplore((prev) => ({ ...prev, [name]: value }))
  }
  const handleGifSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedGifUrl(URL.createObjectURL(selectedFile))
    setGif(selectedFile)
  }
  const handleUpdateExplore = async () => {
    const lastGifUrl = explore.gif ? explore.gif : await uploadToS3(gif, 'gif')
    const gpqResponse = await updateExploreMutation({
      variables: {
        exploreId: id,
        prompt: explore.prompt,
        url: explore.url,
        thumbnailUrl: explore.thumbnailUrl,
        motionScore: explore.motionScore && explore.motionScore.toLowerCase(),
        style: explore.style,
        aspectRatio: explore.aspectRatio,
        gif: lastGifUrl,
        categoryId: explore.categoryId,
      },
    })
    if (gpqResponse.data) {
      setMessage('Explore successfully updated.')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    } else {
      setMessage('Error')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    }
  }
  const handleDeleteExplore = async () => {
    const gpqResponse = await deleteExploreMutation({
      variables: {
        exploreId: id,
      },
    })
    if (gpqResponse.data) {
      setMessage('Explore successfully deleted..')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    } else {
      setMessage('Error')
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    }
  }
  return (
    <>
      {
        message &&
        <div className="fixed-top">
          <Alert severity="success" color="info">
            {message}
          </Alert>
        </div>
      }
      <div className="container mt-4">

        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <TextField disabled fullWidth id="filled-disabled" label="Created at" variant="filled"
                           value={videoData ? videoData.createdAt : ''} />
                <TextField disabled fullWidth id="filled-disabled" label="ID" variant="filled"
                           value={videoData ? videoData.id : ''} />
                <TextField onChange={handleChange} name="prompt" fullWidth id="filled-disabled" label="Prompt"
                           variant="filled"
                           value={explore.prompt} />

                <TextField onChange={handleChange} name="thumbnailUrl" fullWidth id="filled-disabled"
                           label="Thumbnail Url" variant="filled"
                           value={explore.thumbnailUrl} />
                <TextField onChange={handleChange} fullWidth id="filled-disabled" label="Video Url" variant="filled"
                           value={explore.url} />
                <div className="w-25 mb-3">
                  <span>Category</span>
                  <FormControl fullWidth>
                    <Select
                      name="categoryId"
                      onChange={handleChange}
                      value={explore.categoryId}
                    >
                      <MenuItem value={null}>EMPTY</MenuItem>
                      {
                        selectCategories.map(i => (
                          <MenuItem value={i.id}>{i.name}</MenuItem>
                        ))

                      }

                    </Select>
                  </FormControl>
                </div>

                <div>
                  {
                    videoData && videoData.thumbnailUrl && (
                      <img className="img-thumbnail" src={videoData ? videoData.thumbnailUrl : ''}
                           alt="Video Thumbnail" />
                    )
                  }

                </div>
                <div className="d-flex justify-content-center mt-3">
                  <div className="embed-responsive embed-responsive-16by9">
                    <ReactPlayer className="embed-responsive-item" url={videoData ? videoData.url : ''} controls
                                 playing />
                  </div>
                </div>
              </div>
              <div className=" d-block">
                {
                  videoData && videoData.gif && (
                    <div>

                      <img className="img-thumbnail w-50" src={videoData ? selectedGifUrl : ''}
                           alt="Video Thumbnail" />
                      <input
                        name="gif"
                        type="file"
                        onChange={handleGifSelected}

                      />
                    </div>
                  )
                }

              </div>
              <div className="card-footer d-flex justify-content-between bg-light">
                <button onClick={handleUpdateExplore} className="btn btn-primary"><SaveIcon /> Save</button>
                <button onClick={handleDeleteExplore} className="btn btn-danger"><DeleteIcon /> Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
