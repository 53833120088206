import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { uploadToS3 } from '../../utils/uploadToS3'
import Alert from '@mui/material/Alert'
import { useMutation, useQuery } from '@apollo/client'
import { getAllStyles } from '../../graphql/queries'
import CircularProgress from '@mui/material/CircularProgress'
import ReactPlayer from 'react-player'
import { createCategory } from '../../graphql/mutation'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'


const isExplore = [{ value: false, name: 'FALSE' }, { value: true, name: 'TRUE' }]


const categoryType = [{ value: 'TRANSITION', name: 'TRANSITION' }, {
  value: 'MORPHING',
  name: 'MORPHING',
}, { value: 'IMAGE2VIDEO', name: 'IMAGE2VIDEO' }, { value: 'HUGGING', name: 'HUGGING' }]

const models = [{ value: 'GEN2', name: 'GEN2' }, { value: 'GEN3', name: 'GEN3' }, { value: 'LUMA', name: 'LUMA' }]

export default function CreateCategoryPage() {
  const [file, setFile] = useState(null)
  const [selectedFileUrl, setSelectedFileUrl] = useState()
  const [video, setVideo] = useState(null)
  const [gif, setGif] = useState(null)
  const [selectedGifUrl, setSelectedGifUrl] = useState()
  const [selectedVideoUrl, setSelectedVideoUrl] = useState()
  const [show, setShow] = React.useState('none')
  const [detailInputs, setDetailInputs] = useState([{ id: 1, value: null }])
  const [progress, setProgress] = useState(false) // State for progress bar
  const [category, setCategory] = useState({
    name: null,
    thumbnailUrl: null,
    description: null,
    model: null,
    video: null,
    gif: null,
    categoryType: null,
    isExplore: null,
  })
  const { loading, error, data } = useQuery(getAllStyles)

  const selectStyleData = data && data.getAllStyles ? data.getAllStyles : []
  const handleChange = (e) => {
    const { name, value } = e.target
    setCategory((prev) => ({ ...prev, [name]: value }))
  }

  const handleBannerFileSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedFileUrl(URL.createObjectURL(selectedFile))
    setFile(selectedFile)
  }
  const handleVideoSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedVideoUrl(URL.createObjectURL(selectedFile))
    setVideo(selectedFile)
  }
  const handleGifSelected = (e) => {
    const selectedFile = e.target.files[0]
    setSelectedGifUrl(URL.createObjectURL(selectedFile))
    setGif(selectedFile)
  }

  const handleAddDetailInput = () => {
    const newId = detailInputs.length + 1
    setDetailInputs([...detailInputs, { id: newId, value: '' }])
  }
  const handleDetailInputChange = (e, inputId) => {
    const inputValue = e.target.value

    setDetailInputs((prevInputs) => {
      return prevInputs.map((input) => input.id === inputId ? { ...input, value: inputValue } : input)
    })
  }

  const deleteInput = (id) => {
    setDetailInputs(detailInputs.filter(input => input.id !== id))
  }

  const [createExploreMutation] = useMutation(createCategory)
  const handleCreateCategory = async () => {
    const prompts = detailInputs.map(e => e.value)
    setProgress(true)
    const s3VideoUrl = video ? await uploadToS3(video) : null
    const s3ThumbnailUrl = file ? await uploadToS3(file, 'img') : null
    const s3GifUrl = gif ? await uploadToS3(gif, 'gif') : null
    const gpqResponse = await createExploreMutation({
      variables: {
        video: s3VideoUrl,
        thumbnailUrl: s3ThumbnailUrl,
        gif: s3GifUrl,
        prompt: prompts[0] !== null ? prompts : [],
        description: category.description,
        name: category.name,
        model: category.model,
        categoryType: category.categoryType,
        isExplore: category.isExplore,
      }, onCompleted: () => {
        setProgress(false)
      },

    })
    if (gpqResponse.data) {
      setShow('block')
      // Set "none" after 3 seconds
      setTimeout(() => {
        setShow('none')
      }, 3000)
    }
  }
  return (<div>
    <div className="fixed-top" style={{ display: show }}>
      <Alert severity="success" color="info">
        Explore successfully created.
      </Alert>
    </div>
    <div className="d-flex flex-column">

      <div className="w-25 mb-3">
        <TextField
          fullWidth
          id="filled-basic"
          label="Name"
          variant="filled"
          name="name"
          onChange={handleChange}
        />
      </div>
      <div className="w-25 mb-3">
        <TextField
          fullWidth
          id="filled-basic"
          label="Description"
          variant="filled"
          name="description"
          onChange={handleChange}
        />
      </div>
      <div className="w-25 mb-3">
        <span>Type</span>
        <FormControl fullWidth>
          <Select
            name="categoryType"
            onChange={handleChange}
          >
            {categoryType.map(i => (<MenuItem value={i.name}>{i.value}</MenuItem>))}
          </Select>
        </FormControl>
        {progress && <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
          <CircularProgress />
        </div>}
      </div>
      <div className="mb-3 w-25">
        <span>isExplore</span>
        <FormControl fullWidth>
          <Select
            name="isExplore"
            onChange={handleChange}
            value={category.isExplore}
          >
            {isExplore.map(i => (<MenuItem value={i.value}>
              {i.name}
            </MenuItem>))}
          </Select>

        </FormControl>
      </div>
      <div className="w-25 mb-3">
        <span>Model</span>
        <FormControl fullWidth>
          <Select
            name="model"
            onChange={handleChange}
          >
            {models.map(i => (<MenuItem value={i.name}>{i.value}</MenuItem>))}
          </Select>
        </FormControl>
        {progress && <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
          <CircularProgress />
        </div>}
      </div>
      <div className="mb-3">
        <div className="d-flex align-items-center">
          <span className="fs-4">Details</span>
          <AddIcon onClick={handleAddDetailInput} />
        </div>

        {detailInputs.map((input) => (<div key={input.id} className="mb-3 d-flex align-items-center">
          <TextField
            id={`filled-basic-detail-${input.id}`}
            label="Prompt"
            variant="filled"
            value={input.value}
            onChange={(e) => handleDetailInputChange(e, input.id)}
          />
          <ClearIcon onClick={() => deleteInput(input.id)} />
        </div>))}
      </div>
      <span>Thumbnail Photo</span>
      <div className="mb-3 d-flex flex-column  pb-2 pt-2">
        <input
          name="banner"
          type="file"
          onChange={handleBannerFileSelected}
        />
        {selectedFileUrl && <img style={{ width: '25%' }} src={selectedFileUrl} />}
      </div>
      <span>Video</span>
      <div className="mb-3 d-flex flex-column  pb-2 pt-2">
        <input
          name="banner"
          type="file"
          onChange={handleVideoSelected}
        />
        {selectedVideoUrl && <div className="d-flex justify-content-start mt-3">
          <div>
            <ReactPlayer url={selectedVideoUrl} controls playing />
          </div>
        </div>}

      </div>
      <span>Gif</span>
      <div className="mb-3 d-flex flex-column  pb-2 pt-2">
        <input
          name="banner"
          type="file"
          onChange={handleGifSelected}
        />
        {selectedGifUrl && <img style={{ width: '25%' }} src={selectedGifUrl} />}

      </div>
      <div className="d-flex justify-content-start">
        <button
          onClick={handleCreateCategory}
          className="btn btn-dark fs-6"
        >
          SAVE
        </button>
      </div>
    </div>
  </div>)
}
